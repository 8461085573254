import React from 'react'
import styled from 'styled-components'
import bannerImg from 'src/assets/images/banner.png'
import bannerMaleDoctor from 'src/assets/images/banner-male-doctor.png'
import ButtonCustom from 'src/components/form/Button'
import { BASE_WEB_CLINICS_URL } from 'src/utils/constant'

const HeaderComponent: React.FC = () => {
  return (
    <Banner className="banner">
      <div className="banner__bg"></div>
      <div className="banner__wrp px-3">
        <div
          className="banner__ctn px-3"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <p className="mb-5 text-22">オンラインで完結</p>
          <p className="mb-15 text-32">
            ピル・その他美容薬などが
            <br />
            処方可能
          </p>
          <div className="banner__ctn-item-wrp">
            <p className="banner__ctn-item">
              お悩み解決
              <br />
              多彩なプランを
              <br />
              ご用意
            </p>
            <p className="banner__ctn-item">
              土日祝も
              <br />
              OK
            </p>
            <p className="banner__ctn-item">
              診察料
              <span>
                <span className="lh-32 banner__fee">0</span> 円
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="booking-online px-2">
        <img
          className="img-doctor"
          src={bannerMaleDoctor}
          alt="banner-doctor"
        />
        <ButtonCustom
          className="btn-default btn-gurkha fs-16"
          loading={false}
          onClick={() => window.open(`${BASE_WEB_CLINICS_URL}/hj`)}
          name="オンライン診察を予約する"
          showIconRight={true}
        />
      </div>
    </Banner>
  )
}

const Banner = styled.div`
  position: relative;
  background-color: #e9f6fd;
  .banner {
    &__bg {
      background: url(${bannerImg}) no-repeat;
      background-size: 75%;
      background-position: top;
      height: 720px;
    }

    &__ctn {
      background-size: 100%;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 21px;
      padding-bottom: 100px;

      &-item-wrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-item {
        background-color: #fff;
        width: 105px;
        height: 105px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 13px;
        margin: 0;
        font-weight: bold;
      }
    }

    &__fee {
      font-size: 32px;
      line-height: 32px;
    }
  }

  .booking-online {
    width: 100%;
    height: 100px;
    background-color: #d5edf9;
    transition: opacity 0.3s linear;
    display: flex;

    .ant-btn,
    p {
      letter-spacing: 0.2em;
    }

    .img-doctor {
      width: 86px;
      height: 91px;
      margin-top: 9px;
    }

    .btn-gurkha {
      background-color: #2ea7e0;
      margin-right: 1.5rem !important;
      border-color: #2ea7e0;
    }
  }

  .text-22 {
    font-size: 22px;
  }

  .text-32 {
    font-size: 32px;
  }

  @media (max-width: 600px) {
    .banner {
      &__bg {
        height: 640px;
      }
    }
  }

  @media (max-width: 480px) {
    .banner {
      &__bg {
        height: 505px;
      }
    }
    .text-32 {
      font-size: 24px;
    }
    .fs-16 {
      font-size: 14px !important;
    }
  }

  @media (max-width: 380px) {
    .banner {
      &__bg {
        height: 450px;
      }

      &__slogan {
        p {
          height: 80px;
          width: 80px;
          font-size: 10px;
        }

        img {
          width: 80%;
        }
      }

      &__ctn {
        &-item {
          width: 90px;
          height: 90px;
          font-size: 11px;
        }
      }

      &__fee {
        font-size: 28px;
        line-height: 28px;
      }
    }

    .text-22 {
      font-size: 14px;
    }

    .text-32 {
      font-size: 22px;
    }

    .fs-16 {
      font-size: 12px !important;
    }
  }
`

export default HeaderComponent
