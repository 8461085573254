import clinic_3 from 'src/assets/images/landing/clinic_3.jpg'
import doctor_1 from 'src/assets/images/landing/doctor_1.jpeg'
import clinicLogoHj from 'src/assets/images/landing/clinic_logo_hj.svg'

export const clinicsData = {
  lpClinics: [{ uuid: 'hj', logo: clinicLogoHj }],
  clinics: [
    {
      name: 'Chloe Cheongdam Clinic 赤坂 / クロエ チョンダム クリニック​ アカサカ',
      address: '東京都港区赤坂3-9-3赤坂見附駅前ビル2F/3F',
      access:
        '1. 赤坂見附駅（東京メトロ銀座線・丸ノ内線）から、徒歩約2分 \n 2. 赤坂駅（東京メトロ千代田線）から、徒歩約2分 \n 3. 溜池山王駅（東京メトロ銀座線・南北線）から、徒歩約6分',
      business_hours: '10:00~19:00',
      close_time: '',
      note: '',
      image_url: clinic_3,
      id: 3,
    },
  ],
  doctors: [
    {
      clinic_name: '管理医師（院長）',
      name: 'やすだあゆみ',
      name_kana: '安田あゆみ',
      career_introduction:
        '平成28年 3月　東京医科大学　医学部医学科　卒業 \n 平成29年10月　日本皮膚科学会　所属 \n 平成30年 3月　虎の門病院　初期臨床研修修了 \n 平成30年 4月　東京大学医学部　皮膚科学教室入局 \n 令和2年 10月　大手美容クリニック勤務 \n 令和3年 11月　銀座フェミークリニック　院長就任',
      image_url: doctor_1,
      id: 1,
    },
  ],
}
