import React, { Fragment } from 'react'
import styled from 'styled-components'

import { clinicsData } from '../utils/clinics'

const ClinicList: React.FC = () => {
  return (
    <div>
      {clinicsData.clinics.map((clinic) => {
        const clinicAccessBreakText = clinic.access.split('\n')
        const clinicAddressBreakText = clinic.address.split('\n')

        return (
          <div key={clinic.id} data-aos="fade-up" data-aos-delay={500}>
            <h4 className="clinic-header">{clinic.name}</h4>
            <img src={clinic.image_url} alt="" />
            <div className="clinic-info">
              <div className="clinic-info-item">
                <div className="clinic-info-left item-title">住所</div>
                <div className="clinic-info-right">
                  {clinicAddressBreakText.map((data) => {
                    return <div key={data}>{data}</div>
                  })}
                </div>
              </div>
              <div className="clinic-info-item">
                <div className="clinic-info-left item-title">アクセス</div>
                <div className="clinic-info-right">
                  {clinicAccessBreakText.map((data) => {
                    return <div key={data}>{data}</div>
                  })}
                </div>
              </div>
              <div className="clinic-info-item">
                <div className="clinic-info-left item-title">営業時間</div>
                <div className="clinic-info-right">
                  <p>{clinic.business_hours}</p>
                  <p>{clinic.note}</p>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const DoctorList: React.FC = () => {
  return (
    <Fragment>
      {clinicsData.doctors.map((doctor) => {
        const doctorBreakText = doctor.career_introduction.split('\n')
        return (
          <div key={doctor.id} data-aos="fade-up" data-aos-delay={600}>
            <div className="doctor-header">
              <img src={doctor.image_url} alt="" />
              <div>
                <p className="fs-14 font-weight-bold">{doctor.clinic_name}</p>
                <p>{doctor.name}</p>
                <p className="fs-22 font-weight-bold">{doctor.name_kana}</p>
              </div>
            </div>
            <div>
              <div className="item-title">経歴紹介</div>
              <div className="list-exp">
                {doctorBreakText.map((data) => {
                  return <div key={data}>{data}</div>
                })}
              </div>
            </div>
          </div>
        )
      })}
    </Fragment>
  )
}

const ClinicComponent: React.FC = () => {
  return (
    <Wrapper id="clinic">
      <h4 className="com-header" data-aos="fade-down">
        提携クリニック紹介
      </h4>
      <ClinicList />
      <h4 className="com-header" data-aos="fade-down">
        医師紹介
      </h4>
      <DoctorList />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: rgb(186, 204, 224, 0.2);

  .com-header {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-top: 50px;
  }

  .clinic-header {
    font-weight: bold;
    margin-top: 30px;
  }

  .clinic-info {
    &-item {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
    }

    &-left {
      width: 18%;
      text-align: right;
      margin-right: 20px;
      color: var(--color-gurkha);
      font-weight: bold;
    }

    &-right {
      width: 80%;
    }
  }

  img {
    width: 100%;
    margin-top: 10px;
  }

  .doctor-header {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;

    img {
      width: 40%;
      margin-right: 10px;
    }
    div {
      width: 50%;
    }
  }

  .item-title {
    color: var(--color-gurkha);
    font-weight: bold;
  }

  .list-exp {
    font-weight: 400;
    font-size: 14px;
    color: #0e0d0a;
    line-height: 28px;
  }
`

export default ClinicComponent
