import React from 'react'
import styled from 'styled-components'

import paymentIcon from 'src/assets/images/faq/payment.svg'
import treatmentIcon from 'src/assets/images/faq/treatment.svg'
import deliveryIcon from 'src/assets/images/faq/delivery.svg'
import regularDeliveryIcon from 'src/assets/images/faq/regular-delivery.svg'
import reserveIcon from 'src/assets/images/faq/reserve.svg'
import ownExpenseIcon from 'src/assets/images/faq/own-expense.svg'
import medicineIcon from 'src/assets/images/faq/medicine.svg'

const faqsList = [
  {
    id: 1,
    image: paymentIcon,
    name: '決済について',
    link: 'https://faq.bioportplus.jp/payment',
  },
  {
    id: 2,
    image: treatmentIcon,
    name: '診療について',
    link: 'https://faq.bioportplus.jp/treatment',
  },
  {
    id: 3,
    image: deliveryIcon,
    name: '配送について',
    link: 'https://faq.bioportplus.jp/delivery',
  },
  {
    id: 4,
    image: regularDeliveryIcon,
    name: '定期配送について',
    link: 'https://faq.bioportplus.jp/regular-delivery',
  },
  {
    id: 5,
    image: reserveIcon,
    name: 'ご予約・ログインについて',
    link: 'https://faq.bioportplus.jp/reserve',
  },
  {
    id: 6,
    image: ownExpenseIcon,
    name: '自費診療について',
    link: 'https://faq.bioportplus.jp/own-expense',
  },
  {
    id: 7,
    image: medicineIcon,
    name: 'お薬について',
    link: 'https://faq.bioportplus.jp/medicine',
  },
  {
    id: 8,
    image: '',
    name: 'その他',
    link: 'https://faq.bioportplus.jp/other',
  },
]

const FAQComponent: React.FC = () => {
  const handleOpenLink = (link) => {
    window.location.assign(link)
  }

  return (
    <Wrapper id="faq">
      <div className="faq-header" data-aos="fade-down">
        よくある質問
      </div>
      <div className="text-left mb-30" data-aos="fade-up">
        <p>
          「医薬品」は有効成分の効果が認められたもので、治療または予防に使用されます。
        </p>
        <p>
          市販の化粧品やサプリメントの効果効能は医薬品と異なり、実証されていることが少ない為、補助として利用されることが多いです。
        </p>
      </div>
      <div className="faq-link">
        {faqsList.map((faq) => {
          return (
            <div
              onClick={() => handleOpenLink(faq.link)}
              className="faq-item c-pointer cl-black"
              key={faq.id}
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {faq.image && <img src={faq.image} alt="" />}
              <p className={faq.image ? '' : 'pb-0'}>{faq.name}</p>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;

  .faq-header {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 50px auto 30px;
  }

  .faq-link {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .faq-item {
    border: 1px solid #9f9f99;
    border-radius: 10px;
    width: 47%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &:hover {
      box-shadow: 0px 4px 10px var(--color-shadow-25);
    }

    img {
      padding: 20px;
    }

    p {
      width: 100%;
      padding: 0 15px 15px;
    }
  }
`

export default FAQComponent
